import React, { useEffect } from 'react';
import useMutateRequest from '../../../../hooks/getMutation.query';
import { getLatestPatientVitals } from '../../../../services/patient/PatientManagement.hook';

function Vitals({ id, facilityCode, age, gender }: any) {
    // Define vitals data with color-coded status
    const getVitalStatus = (vitalRange: string | null) => {
        if (vitalRange === undefined) return 'badge bg-danger';

        return 'vitals-value';
    };

    const { data, isLoading, error, mutate, isError }: any = useMutateRequest(
        (data: any) =>
            getLatestPatientVitals({
                uhid: id,
                facilityCode,
                age,
                gender,
            })
    );

    const getData = () => {
        try {
            const formData = new FormData();
            formData.append('uhid', id);
            formData.append('facilityCode', facilityCode);
            formData.append('age', age);
            formData.append('gender', gender);

            mutate(formData, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {},
            });
        } catch (error) {}
    };

    useEffect(() => {
        if (id) getData();
    }, [id]);

    // Destructure vitals data
    const vitals = data?.data?.data || {};
    const {
        height,
        weight,
        bloodPressure,
        oxygenSaturation,
        pulse,
        temperature,
        bloodGlucose,
        hemoglobin,
        respiratory,
        ecg,
    } = vitals;

    return (
        <div className="card border-0 shadow-sm overflow-hidden">
            <div className="card-header bg-gradient-primary text-white py-4 text-center">
                <h2 className="mb-0 d-flex align-items-center justify-content-center">
                    <i className="fas fa-heartbeat me-3"></i>
                    PATIENT VITALS
                </h2>
            </div>

            <div className="card-body p-0">
                <div className="row g-0">
                    {/* Left Column */}
                    <div className="col-md-6 border-end p-4">
                        <div className="vitals-group">
                            <div className="vitals-item">
                                <span className="vitals-label">Height</span>
                                <span
                                    className={`${getVitalStatus(
                                        height?.vitalRange
                                    )}`}
                                >
                                    {height?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">Weight</span>
                                <span
                                    className={`${getVitalStatus(
                                        weight?.vitalRange
                                    )}`}
                                >
                                    {weight?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">
                                    Temperature
                                </span>
                                <span
                                    className={`${getVitalStatus(
                                        temperature?.vitalRange
                                    )}`}
                                >
                                    {temperature?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">
                                    Blood Glucose
                                </span>
                                <span
                                    className={`${getVitalStatus(
                                        bloodGlucose?.vitalRange
                                    )}`}
                                >
                                    {bloodGlucose?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">ECG</span>
                                <span
                                    className={`${getVitalStatus(
                                        ecg?.vitalRange
                                    )}`}
                                >
                                    {ecg?.value || 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-md-6 p-4">
                        <div className="vitals-group">
                            <div className="vitals-item">
                                <span className="vitals-label">BP</span>
                                <span
                                    className={`${getVitalStatus(
                                        bloodPressure?.vitalRange
                                    )}`}
                                >
                                    {bloodPressure?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">
                                    Oxygen Saturation
                                </span>
                                <span
                                    className={`${getVitalStatus(
                                        oxygenSaturation?.vitalRange
                                    )}`}
                                >
                                    {oxygenSaturation?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">Pulse</span>
                                <span
                                    className={`${getVitalStatus(
                                        pulse?.vitalRange
                                    )}`}
                                >
                                    {pulse?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">Hemoglobin</span>
                                <span
                                    className={`${getVitalStatus(
                                        hemoglobin?.vitalRange
                                    )}`}
                                >
                                    {hemoglobin?.value || 'N/A'}
                                </span>
                            </div>
                            <div className="vitals-item">
                                <span className="vitals-label">
                                    Respiratory
                                </span>
                                <span
                                    className={`${getVitalStatus(
                                        respiratory?.vitalRange
                                    )}`}
                                >
                                    {respiratory?.value || 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Hover Effects and Custom Styling */}
            <style>{`
                .vitals-group {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                .vitals-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.75rem 1rem;
                    border-radius: 8px;
                }

                .vitals-item:hover {
                    background-color: rgba(0, 123, 255, 0.05);
                }

                .vitals-label {
                    font-weight: 600;
                    color: #6c757d;
                    transition: color 0.3s ease;
                }

                .vitals-value {
                    font-weight: 700;
                    color: #343a40;
                    transition: color 0.3s ease;
                }

                .vitals-item:hover .vitals-label {
                    color: #007bff;
                }

                .vitals-item:hover .vitals-value {
                    color: #212529;
                }
            `}</style>
        </div>
    );
}

export default Vitals;
