import { Link, useNavigate, useLocation } from 'react-router-dom';
import { KTSVG } from '../_metronic/helpers';
import Breadcrumbs from './Breadcrumbs';
import FilterRange from './FilterRange';
import { useAppInfo } from '../common/config/useAppInfo';

const Navbar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { loggedInUser } = useAppInfo();

    const chwPaths = ['/chw/', '/chw/chw-management', '/chw/chw-settings'];
    const doctorPaths = [
        '/doctor/',
        '/doctor/doctor-management',
        '/doctor/doctor-settings',
    ];
    const facilityPaths = ['/facility/facility-management'];

    const arrPaths = [
        '/dashboard',
        '/teleconsultation',
        '/physical-consultation',
        '/patient-management',
        '/vitals/vitals-management',
        '/vitals/vitals-insights',
    ];
    const chwOnboard = chwPaths.includes(pathname);
    const doctorOnboard = doctorPaths.includes(pathname);
    const facilityOnboard = facilityPaths.includes(pathname);
    // const goBack = taskDetailsPaths.includes(pathname);
    // const showGoBack = match && match.isExact;
    const filterPath = arrPaths.includes(pathname);

    return (
        <div className="d-flex justify-content-between align-items-center h-60px">
            <div>
                <Breadcrumbs />
            </div>
            {/* Filter */}
            {filterPath === true ? (
                <div className="d-flex justify-content-center align-items-center">
                    <FilterRange />
                </div>
            ) : chwOnboard === true ? (
                <OnboardBtn urlPath="/chw/chw-onboarding" name="CHW" />
            ) : doctorOnboard === true ? (
                <OnboardBtn urlPath="/doctor/doctor-onboarding" name="DOCTOR" />
            ) : facilityOnboard === true ? (
                <OnboardBtn
                    urlPath="/facility/facility-onboarding"
                    name="FACILTY"
                />
            ) : pathname === '/focused-group/management' ? (
                <Link
                    to={`/focused-group/create-focused-group`}
                    className="btn btn-sm btn-primary"
                >
                    <i className="fas fa-plus"></i>
                    Create Focused Group
                </Link>
            ) : pathname === '/training/content-management' ? (
                <Link
                    to={`/training/create-content`}
                    className="btn btn-sm btn-primary"
                >
                    Create Content
                </Link>
            ) : pathname === '/indent/indent-list' &&
              loggedInUser !== 'ORGANIZATION_ADMIN' ? (
                <Link
                    to={`/indent/modify-indent`}
                    className="btn btn-sm btn-primary"
                >
                    Create Indent
                </Link>
            ) : (
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => navigate(-1)}
                >
                    Go Back
                </button>
            )}
        </div>
    );
};

export default Navbar;

const OnboardBtn = ({ urlPath, name }: any) => {
    return (
        <>
            <Link to={urlPath} className="btn btn-sm btn-primary me-5">
                <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                />
                {`${name}  ONBOARDING`}
            </Link>
        </>
    );
};
