import { useEffect, useMemo, useState } from 'react';
import { convertToUtcTimestamp } from '../../common/utils';
import { useNavbar } from '../../context/NavbarContext';
import useMutateRequest from '../../hooks/getMutation.query';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllDoctors } from '../../services/doctor/DoctorManagement.hook';
import { getAllCHWByFacility } from '../../services/focused-group/FocusedGroup.hook';
import { getPatientConsultationsByFacility } from '../../services/patient/PatientAppointments.hook';

import AppointmentCounterPage from './AppointmentCounterPage';
import AppointmentListTable from './table/AppointmentListTable';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const AppointmentPage = () => {
    const { selectedFacility, userRole, loggedInUser } = useNavbar();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterData, setFilterData]: any = useState({
        // fromDate: todayDate,
        // toDate: todayDate,
        fromDate: '',
        toDate: '',
        doctorName: userRole === 'DOCTOR' ? loggedInUser?.userName : '',
        chwName: '',
        consultationType: '',
        specialization: '',
        status: '',
    });

    const facilityCode: any = localStorage.getItem('facilityCode');

    const {
        data,
        isLoading,
        error,
        mutate,
        isError: isPatientConsultationError,
    }: any = useMutateRequest((data: any) =>
        getPatientConsultationsByFacility({
            facilityCode,
            page: currentPageNumber,
            limit: pageLimit,
            fromDate:
                filterData?.fromDate !== ''
                    ? convertToUtcTimestamp(filterData?.fromDate)
                    : '',
            toDate:
                filterData?.toDate !== ''
                    ? convertToUtcTimestamp(filterData?.toDate)
                    : '',
            status: filterData?.status,
            consultationType: filterData?.consultationType,
            specialization: filterData?.specialization,
            doctorName:
                userRole === 'DOCTOR'
                    ? loggedInUser?.userName
                    : filterData?.doctorName,
            chwName: filterData?.chwName,
        })
    );

    const patientConsultationDetails: any = data?.data?.data?.consultation;
    const totalPatientAppointmentCount: any =
        data?.data?.data?.consultationCount;
    const totalPatientConsultationEntites = data?.data?.results;

    const totalPatientConsultationPages = Math.ceil(
        totalPatientConsultationEntites / pageLimit
    );

    const countObj = useMemo(
        () => ({
            done: data?.data?.data?.doneCounts,
            scheduled: data?.data?.data?.scheduledCounts,
            rescheduled: data?.data?.data?.rescheduledCounts,
            inProgress: data?.data?.data?.inProgressCounts,
            cancelled: data?.data?.data?.cancelledCounts,
        }),
        [data]
    );

    const errorMsg = useMemo(
        () =>
            isErrorMsgNotReadable(error?.response?.data?.message?.message)
                ? getErrorMessage(error?.response?.status)
                : error?.response?.data?.message?.message,
        [error]
    );
    // const successMsg = data?.data?.data?.message;

    const getData = () => {
        try {
            let payload = {
                facilityCode,
                // fromDate: filterData?.fromDate,
                // toDate: filterData?.toDate,
                fromDate:
                    filterData?.fromDate !== ''
                        ? convertToUtcTimestamp(filterData?.fromDate)
                        : '',
                toDate:
                    filterData?.toDate !== ''
                        ? convertToUtcTimestamp(filterData?.toDate)
                        : '',
                doctorName:
                    userRole === 'DOCTOR'
                        ? loggedInUser?.userName
                        : filterData?.doctorName,
                chwName: filterData?.chwName,
            };
            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const { data: allCHW, refetch: refetchCHW }: any = useGetRequest(
        'allCHWData',
        async () =>
            await getAllCHWByFacility({
                facilityCode,
                page: 1,
                limit: 30,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const chwData = allCHW?.data?.data?.chw;

    const { data: doctorData, refetch: refetchDoctor }: any = useGetRequest(
        'getAllDoctors',
        async () => await getAllDoctors(),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const doctorDetails = doctorData?.data?.data?.doctor;

    useEffect(() => {
        setFilterData((prevData: any) => ({
            ...prevData,
            doctorName: userRole === 'DOCTOR' ? loggedInUser?.userName : '',
        }));
    }, [userRole, loggedInUser]);

    useEffect(() => {
        getData();
        refetchDoctor();
        refetchCHW();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageNumber, filterData, selectedFacility]);

    return (
        <>
            <div>
                <AppointmentCounterPage
                    filterData={filterData}
                    totalPatientConsultationEntites={
                        totalPatientConsultationEntites
                    }
                    isLoading={isLoading}
                    totalPatientAppointmentCount={totalPatientAppointmentCount}
                    isPatientConsultationError={isPatientConsultationError}
                    countObj={countObj}
                />
            </div>
            <div className="mt-5">
                <AppointmentListTable
                    setCurrentPageNumber={setCurrentPageNumber}
                    currentPageNumber={currentPageNumber}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    patientConsultationDetails={patientConsultationDetails}
                    doctorDetails={doctorDetails}
                    chwData={chwData}
                    errorMsg={errorMsg}
                    isLoading={isLoading}
                    totalPatientConsultationPages={
                        totalPatientConsultationPages
                    }
                    isPatientConsultationError={isPatientConsultationError}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    totalPatientConsultationEntites={
                        totalPatientConsultationEntites
                    }
                    facilityCode={facilityCode}
                />
            </div>
        </>
    );
};

export default AppointmentPage;
