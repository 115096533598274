import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    initialValues,
    prescriptionValidationSchema,
} from '../../model/AddPrescriptionModel';
import CheckBox from '../../../../components/Checkbox';
import MedicineRow from './prescription-form/MedicineRow';
import EyePrescriptionFields from './prescription-form/EyePrescriptionFields';
import ClipLoader from 'react-spinners/ClipLoader';

const PrescriptionForm = ({ appointmentDetails, onCancel }: any) => {
    const [isGlassPrescription, setIsGlassPrescription] = useState(false);
    const handleIsChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsGlassPrescription(e.target.checked);
    };

    return (
        <div className="container mt-4 pb-6">
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>Add Prescription</h2>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={prescriptionValidationSchema(
                    isGlassPrescription
                )}
                onSubmit={() => {}}
            >
                {({ values, errors, touched, isSubmitting }) => (
                    <Form className="needs-validation">
                        {/* Patient Info */}
                        <div className="row g-3 mb-4">
                            <div className="col-md-4">
                                <label className="form-label">
                                    Patient Name
                                </label>
                                <input
                                    type="text focus-ring"
                                    className="form-control"
                                    value={
                                        appointmentDetails?.patientName || ''
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-md-2 ms-auto">
                                <label className="form-label">Gender</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={appointmentDetails?.date || ''}
                                    disabled
                                />
                            </div>
                            <div className="col-md-1 ms-auto">
                                <label className="form-label">Age</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={appointmentDetails?.date || ''}
                                    disabled
                                />
                            </div>
                            <div className="col-md-3 ms-auto">
                                <label className="form-label">
                                    Appointment Date
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={appointmentDetails?.date || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        {/* Patient Complaint */}
                        <div className="mb-4">
                            <label className="form-label required">
                                Patient Complaint
                            </label>
                            <Field
                                as="textarea"
                                name="patientComplaint"
                                data-testid="patient-complaint"
                                className={`form-control ${
                                    errors.patientComplaint &&
                                    touched.patientComplaint
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                rows="3"
                            />
                            <ErrorMessage
                                name="patientComplaint"
                                component="div"
                                className="invalid-feedback"
                            />
                        </div>
                        {/* Checkbox for glass prescription */}
                        <div className="form-check mb-4">
                            <CheckBox
                                name={'check-box'}
                                onChange={handleIsChecked}
                                isChecked={isGlassPrescription}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                            >
                                Add Glass Prescription
                            </label>
                        </div>

                        {/* Eye Prescription */}
                        {isGlassPrescription && <EyePrescriptionFields />}

                        {/* Doctor's Advice */}
                        <div className="mb-3">
                            <label className="form-label required">
                                Doctor's Advice
                            </label>
                            <Field
                                as="textarea"
                                name="doctorAdvice"
                                data-testid="doctors-advice"
                                className={`form-control ${
                                    errors.doctorAdvice && touched.doctorAdvice
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                rows="3"
                            />
                            <ErrorMessage
                                name="doctorAdvice"
                                component="div"
                                className="invalid-feedback"
                            />
                        </div>

                        {/* Medicines */}
                        <div className="mb-4">
                            <label className="form-label required">
                                Medicine
                            </label>
                            <FieldArray name="medicines">
                                {({ push, remove }) => (
                                    <div>
                                        {values.medicines.map(
                                            (medicine, index) => (
                                                <MedicineRow
                                                    key={index}
                                                    index={index}
                                                    remove={remove}
                                                    push={push}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            )
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-sm mt-2"
                                            onClick={() =>
                                                push({
                                                    name: '',
                                                })
                                            }
                                        >
                                            + Add Medicine
                                        </button>
                                    </div>
                                )}
                            </FieldArray>
                        </div>

                        {/* Lab Advice */}
                        <div className="mb-4">
                            <label className="form-label">Lab Advice</label>
                            <Field
                                as="textarea"
                                name="labAdvice"
                                className="form-control"
                                rows="2"
                            />
                        </div>

                        {/* Other Suggestions */}
                        <div className="mb-3">
                            <label className="form-label">
                                Other Suggestions
                            </label>
                            <Field
                                as="textarea"
                                name="otherSuggestions"
                                className="form-control"
                                rows="2"
                            />
                        </div>

                        {/* Form Actions */}
                        <div className="d-flex justify-content-end gap-2 mt-">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <div className="d-flex align-items-center">
                                        <ClipLoader
                                            size={20}
                                            speedMultiplier={0.6}
                                            color="white"
                                        />
                                        <span className="ms-2">Saving...</span>
                                    </div>
                                ) : (
                                    'Save Prescription'
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PrescriptionForm;
