import React, { useState, useEffect } from 'react';

const BookingGuidance = ({ onComplete }: any) => {
    const [step, setStep] = useState(0);

    const steps = [
        {
            text: 'Hi doctor! This page contains booking and patient information.',
            position: 'center',
            highlight: null,
        },

        {
            text: 'If want you accept or reject the booking in the below highlighted section',
            position: 'last',
            highlight: 'booking-action-buttons',
        },
    ];

    /*Disabling mouse Input */

    useEffect(() => {
        // Prevent scrolling
        document.body.style.overflow = 'hidden';

        // Disable scrolling and mouse interactions
        const preventScroll = (e: Event) => {
            e.preventDefault();
        };

        // Disable scroll, wheel, and touch events
        window.addEventListener('scroll', preventScroll, { passive: false });
        window.addEventListener('wheel', preventScroll, { passive: false });
        window.addEventListener('touchmove', preventScroll, { passive: false });

        // Optional: Disable mouse interactions on background
        const disableMouseInteractions = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (!target.closest('.booking-guidance-overlay')) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        document.addEventListener('mousedown', disableMouseInteractions, {
            capture: true,
        });
        document.addEventListener('click', disableMouseInteractions, {
            capture: true,
        });

        // Cleanup function to restore normal interactions
        return () => {
            document.body.style.overflow = 'auto';
            window.removeEventListener('scroll', preventScroll);
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
            document.removeEventListener('mousedown', disableMouseInteractions);
            document.removeEventListener('click', disableMouseInteractions, {
                capture: true,
            });
        };
    }, [step]);

    const handleNext = () => {
        if (step < steps.length - 1) {
            setStep((prev) => prev + 1);
        } else {
            onComplete();
        }
    };

    const currentStep = steps[step];

    return (
        <div className="booking-guidance-overlay">
            {/* Overlay with semi-transparent background */}
            <div className="guidance-backdrop"></div>

            {/* Guidance Message */}
            <div
                className={`guidance-message guidance-${currentStep.position}`}
            >
                <div className="card shadow-lg">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <i className="fas fa-info-circle text-primary me-3 fa-2x"></i>
                            <h5 className="mb-0">
                                Appointment Details Page Guide
                            </h5>
                        </div>
                        <p className="text-muted">{currentStep.text}</p>
                        <div className="d-flex justify-content-between align-items-center">
                            <small className="text-muted">
                                {step < steps.length - 1
                                    ? 'Click Next to continue'
                                    : 'Click Finish to complete'}
                            </small>
                            <button
                                className="btn btn-primary"
                                onClick={handleNext}
                            >
                                {step < steps.length - 1 ? 'Next' : 'Finish'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Optional: Highlight specific areas */}
            {currentStep.highlight && (
                <div
                    className={`guidance-highlight ${currentStep.highlight}`}
                ></div>
            )}
            <style>{`/* Booking Guidance Styles */
.booking-guidance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  pointer-events: none;
}

.guidance-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.guidance-message {
  position: absolute;
  pointer-events: auto;
  max-width: 400px;
  z-index: 1060;
}

.guidance-message.guidance-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  
.guidance-message.guidance-last {
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.guidance-message.guidance-top {
  top: 10px;
  left: 12%;
  transform: translateX(-50%);
}

.guidance-highlight {
  position: absolute;
  border: 3px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8);
  pointer-events: none;
  z-index: 1055;
}

.guidance-highlight.booking-details {
  /* Adjust these values based on your specific layout */
  top: 160px;
  left: 58.5%;
  transform: translateX(-50%);
  width: 81%;
  height: 350px;
}

.guidance-highlight.booking-action-buttons {
  /* Adjust these values based on your specific layout */
  top: 28rem;
  left: 58.8%;
  transform: translateX(-50%);
  width: 28%;
  height: 150px;
}`}</style>
        </div>
    );
};

export default BookingGuidance;
