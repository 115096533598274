import React, { useState } from 'react';
import AddBillingProductForm from './billingForm/billingForm';
import { IProductsArr } from './billingForm/IForms';
import BillingProductTable from './billingProductTable/billingProductTable';
import { getSalesOrderDetails } from '../../../../services/supply-chain/sales-order/sales-order-details/SalesOrderDetails.services';
import useGetRequest from '../../../../hooks/getRequest.query';
import { useSearchParams } from 'react-router-dom';

type Props = {};

const BillingOrder = (props: Props) => {
    const [productsArr, setProductsArr] = useState<IProductsArr>([]);
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId') as string;
    const { data }: any = useGetRequest(['getSalesOrderDetails'], () =>
        getSalesOrderDetails({
            page: 1,
            limit: 100,
            orderId,
        })
    );

    return (
        <div className="card text-dark p-3">
            <AddBillingProductForm
                setProductsArr={setProductsArr}
                customerType={data?.data?.data?.order[0]?.customerType}
            />
            <div className="mt-5">
                {productsArr?.length > 0 && (
                    <BillingProductTable
                        productsArr={productsArr}
                        setProductsArr={setProductsArr}
                    />
                )}{' '}
            </div>
        </div>
    );
};

export default BillingOrder;
