import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {
    convertToUtcTimestamp,
    todayDate,
} from '../../../../common/utils/datesUtils';
import { useNavbar } from '../../../../context/NavbarContext';
import useMutateRequest from '../../../../hooks/getMutation.query';
import useGetRequest from '../../../../hooks/getRequest.query';
import { getAllDoctors } from '../../../../services/doctor/DoctorManagement.hook';
import { getAllCHWByFacility } from '../../../../services/focused-group/FocusedGroup.hook';
import { generateReport } from '../../../../services/report/ReportGeneration.hook';

import {
    appointmentExportValueTypes,
    appointmentListExportValidationSchema,
    appointmentListInitalValues,
} from '../../model/AppointmentExportModel';
import { getAllFacilites } from '../../../../services/facility/Facility.hook';

type ExportProps = {
    onClose: () => void;
    styles?: any;
};

const AppointmentListExport = ({ onClose, styles }: ExportProps) => {
    const facilityName: any = localStorage.getItem('facilityName');
    const facilityCode: any = localStorage.getItem('facilityCode');
    const { selectedFacility, userRole } = useNavbar();
    const navigate = useNavigate();
    const [msg, setMsg] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    // API Call
    const { mutate, isLoading, isError, error, isSuccess } = useMutateRequest(
        (data: any) => generateReport(data)
    );

    const {
        data: allCHW,
        refetch,
        isFetching,
    }: any = useGetRequest(
        ['allCHWData'],
        async () =>
            await getAllCHWByFacility({
                facilityCode,
                page: 1,
                limit: 50,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const chwData = allCHW?.data?.data?.chw;

    const { data: allDoctors, refetch: refetchDoctor }: any = useGetRequest(
        'getAllDoctors',
        async () => await getAllDoctors(),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    const doctorData = allDoctors?.data?.data?.doctor;

    const { data } = useGetRequest(
        'getAllFacilites-header',
        async () => await getAllFacilites(),
        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const facilityData = useMemo(() => {
        return data?.data?.data?.facility
            ?.filter((facility: any) => {
                return facility?.status !== false;
            })
            .map((facility: any) => {
                const facilityCode = facility?.facilityCode;
                const facilityStatus = facility?.status;
                const facilityName =
                    facility?.facilityFirstName +
                    ' ' +
                    facility?.facilityLastName;
                return {
                    facilityCode,
                    facilityName,
                    facilityStatus,
                };
            });
    }, [data]);

    useEffect(() => {
        refetch();
        refetchDoctor();
    }, [facilityCode, selectedFacility]);

    useEffect(() => {
        if (isSuccess === true) {
            setSpinner(true);
            setTimeout(() => {
                onClose();
                setSpinner(false);
                navigate('/report/report-management');
            }, 3500);
        }
    }, [isSuccess]);

    const onSubmit = (values: appointmentExportValueTypes) => {
        let fullPayload = [
            {
                filterName: 'startDate',
                filterValue: convertToUtcTimestamp(values?.fromDate),
            },
            {
                filterName: 'endDate',
                filterValue: convertToUtcTimestamp(values?.toDate),
            },
            {
                filterName: 'chwId',
                filterValue: values?.chwId,
            },
            {
                filterName: 'doctorId',
                filterValue: values?.doctorName,
            },
            {
                filterName: 'consulationStatus',
                filterValue: values?.appointmentStatus,
            },
            {
                filterName: 'facilityCode',
                filterValue: values?.facilityCode
                    ? values?.facilityCode
                    : facilityCode,
            },
        ]?.filter((item) => item?.filterValue !== '');

        let payload: any = {
            reportType: 'exportConsultation',
            reportRepository: 'scheduler',
            filter: fullPayload,
        };

        try {
            mutate(payload, {
                onSuccess: (data: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 2000);
                },
                onError: (error: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 2000);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isLoading || isFetching || spinner ? (
                <div
                    style={{ backgroundColor: 'transparent' }}
                    className="h-225px d-flex flex-column justify-content-center align-items-center"
                >
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : msg ? (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.msgHeight,
                        padding: styles?.padding,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    {isError === true ? (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Failed to submit appointment list report
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={() => setMsg(false)}
                                    className="w-150px btn btn-lg btn-primary me-5"
                                >
                                    Please Retry
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Your request has been successfully submitted
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                    }}
                >
                    <>
                        <h1 className="text-center text-hover-primary">
                            EXPORT OPTIONS
                        </h1>
                    </>

                    <Formik
                        initialValues={appointmentListInitalValues}
                        validationSchema={appointmentListExportValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formProps: any) => (
                            <Form>
                                <div
                                    style={{
                                        height: '450px',
                                        overflow: 'hidden',
                                        overflowY: 'scroll',
                                        scrollbarWidth:
                                            '-moz-initial' /* Firefox */,
                                    }}
                                >
                                    <div className="mt-5">
                                        <label
                                            htmlFor="exportFormat"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            EXPORT FORMAT
                                        </label>
                                        <Field
                                            id="exportFormat"
                                            as="select"
                                            name="exportFormat"
                                            disabled
                                            className="form-select form-select-light form-select-lg required"
                                            style={{ cursor: 'not-allowed' }}
                                        >
                                            <option value="EXCEL">EXCEL</option>
                                            <option value="CSV">CSV</option>
                                            <option value="PDF">PDF</option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="exportFormat" />
                                        </div>
                                        <div className="text-muted fw-bolder mt-2 ms-2">
                                            By Default the Data would be
                                            exported in EXCEL Format.
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <label
                                            htmlFor="exportFormat"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            FACILITY NAME
                                        </label>
                                        <Field
                                            id="facilityCode"
                                            as="select"
                                            name="facilityCode"
                                            className="form-select form-select-light form-select-lg"
                                            disabled={
                                                userRole === 'FACILITY MANAGER'
                                            }
                                            style={{
                                                cursor:
                                                    userRole ===
                                                    'FACILITY MANAGER'
                                                        ? 'not-allowed'
                                                        : '',
                                            }}
                                        >
                                            {userRole === 'FACILITY MANAGER' ? (
                                                <option value={facilityCode}>
                                                    {facilityName}
                                                </option>
                                            ) : (
                                                <>
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {facilityData.map(
                                                        (facility: any) => {
                                                            const {
                                                                facilityCode,
                                                                facilityName,
                                                            } = facility;
                                                            return (
                                                                <option
                                                                    value={
                                                                        facilityCode
                                                                    }
                                                                >
                                                                    {
                                                                        facilityName
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="facilityCode" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            CHW NAME
                                        </label>
                                        <Field
                                            as="select"
                                            name="chwId"
                                            className="form-select form-select-lg form-select-solid"
                                        >
                                            <option value="">All</option>
                                            {chwData?.map((chw: any) => {
                                                return (
                                                    <>
                                                        <option
                                                            value={chw?.chwId}
                                                        >
                                                            {chw?.name}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="chwId" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            DOCTOR NAME
                                        </label>

                                        <Field
                                            as="select"
                                            name="doctorName"
                                            className="form-select form-select-lg form-select-solid"
                                        >
                                            <option value="">All</option>
                                            {doctorData?.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <>
                                                            <option
                                                                value={
                                                                    item?._id
                                                                }
                                                            >
                                                                {item?.name}
                                                            </option>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="doctorName" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            APPOINTMENT STATUS
                                        </label>

                                        <Field
                                            as="select"
                                            name="appointmentStatus"
                                            className="form-select form-select-lg form-select-solid"
                                        >
                                            <option value="">All</option>
                                            <option value="Scheduled">
                                                Scheduled
                                            </option>
                                            <option value="Done">Done</option>
                                            <option value="InProgress">
                                                In Progress
                                            </option>
                                            <option value="Cancelled">
                                                Cancelled
                                            </option>
                                            <option value="Rescheduled">
                                                Rescheduled
                                            </option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="appointmentStatus" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            FROM DATE
                                        </label>
                                        <Field
                                            id="fromDate"
                                            as="input"
                                            type="date"
                                            name="fromDate"
                                            max={todayDate}
                                            className="form-control cursor-pointer"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="fromDate" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            TO DATE
                                        </label>
                                        <Field
                                            id="toDate"
                                            type="date"
                                            name="toDate"
                                            max={todayDate}
                                            className="form-control"
                                        />
                                        <div className="text-danger mt-1">
                                            <ErrorMessage name="toDate" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 h-20px d-flex justify-content-center align-items-center">
                                    <button
                                        onClick={onClose}
                                        className="btn btn-md btn-danger me-5"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={formProps.isSubmitting}
                                        className="btn btn-md btn-primary"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default AppointmentListExport;
