import { Toaster } from 'react-hot-toast';
import { AppProviders } from './providers/AppProviders';
import Routes from './routing/Routes';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';

const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
const App = () => {
    return (
        <AppProviders>
            <AgoraRTCProvider client={client}>
                <Toaster />
                <Routes />
            </AgoraRTCProvider>
        </AppProviders>
    );
};

export { App };
