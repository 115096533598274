import React from 'react';
import { Link } from 'react-router-dom';

const PatientDetails = ({ id, details }: any) => {
    const patientData = {
        name: 'John Doe',
        gender: 'Male',
        age: 32,
        remarks: 'Test',
        symptoms: 'Headache',
    };

    const { sex, age } = details?.patientData[0]?.demographicDetails || {};
    const { remarks, symptoms } = details || {};

    return (
        <div className="card border-0 shadow-sm overflow-hidden">
            <div className="card-header bg-gradient-primary text-white py-4 text-center">
                <h2 className="mb-0 d-flex align-items-center justify-content-center">
                    <i className="fas fa-user-md me-3"></i>
                    PATIENT DETAILS
                </h2>
            </div>

            <div className="card-body p-0">
                <div className="row g-0">
                    {/* Left Side - Patient Profile */}
                    <div className="col-md-4 border-end p-4 text-center">
                        <div className="text-center mb-4">
                            <div className="position-relative d-inline-block">
                                <div
                                    className="rounded-circle bg-light d-flex align-items-center justify-content-center"
                                    style={{ width: '150px', height: '150px' }}
                                >
                                    <i className="fas fa-user-circle fa-5x text-secondary"></i>
                                </div>
                            </div>

                            <h5 className="mt-3 mb-1 fw-bold text-primary">
                                <Link to={`/patient/overview?pId=${id}`}>
                                    {details?.patient?.name}
                                </Link>
                            </h5>
                        </div>
                    </div>

                    {/* Right Side - Patient Details */}
                    <div className="col-8 ps-md-4 py-4">
                        <div className="row g-4 fs-5 mt-3">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="patient-detail-item">
                                        <span className="text-muted me-2 w-25">
                                            Gender:
                                        </span>
                                        <span className="fw-semibold">
                                            {sex}
                                        </span>
                                    </div>

                                    <div className="patient-detail-item mt-3">
                                        <span className="text-muted me-2 w-25">
                                            Age:
                                        </span>
                                        <span className="fw-semibold">
                                            {age}
                                            years
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="patient-detail-item">
                                        <span className="text-muted me-2 w-25">
                                            Remarks:
                                        </span>
                                        <span className="fw-semibold">
                                            {remarks}
                                        </span>
                                    </div>

                                    <div className="patient-detail-item mt-3">
                                        <span className="text-muted me-2 w-25">
                                            Symptoms:
                                        </span>
                                        <span className="fw-semibold">
                                            {symptoms}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Hover Effects Styling */}
            <style>{`
                .patient-detail-item {
                    display: flex;
                    align-items: center;
                    padding: 0.75rem 1rem;
                    border-radius: 8px;
                }
                   

                .patient-detail-item:hover .text-muted {
                    color: #007bff !important;
                    
                }

                .patient-detail-item:hover .fw-semibold {
                    color: #343a40;
                    transition: color 0.3s ease;
                   
                }
            `}</style>
        </div>
    );
};

export default PatientDetails;
