function ConfirmCancelModal({ handleConfirm, handleClose }: any) {
    return (
        <>
            {/* Background overlay */}
            <div
                className="position-fixed top-0 start-0 w-100 h-100 bg-black opacity-50"
                style={{ zIndex: 1040 }}
            ></div>

            {/* Modal overlay */}
            <div
                className="position-fixed top-50 start-50 translate-middle"
                style={{ zIndex: 1050 }}
            >
                <div className="w-400px h-250px bg-white p-10 rounded d-flex flex-column border border-2 position-relative">
                    <div className="text-center mb-3">
                        <h2>Cancel Booking</h2>
                    </div>

                    <div className="text-center mb-3">
                        <p>Are you sure you want to cancel the booking?</p>
                        <p
                            className="text-muted"
                            style={{ fontSize: '0.9rem' }}
                        >
                            Note: This action will change the booking status to{' '}
                            <b>Cancelled</b>.
                        </p>
                    </div>

                    <div className="d-flex justify-content-center gap-6 px-4">
                        <button
                            className="btn btn-secondary px-6 py-2"
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-danger px-6 py-2"
                            onClick={handleConfirm}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmCancelModal;
