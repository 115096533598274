import $axiosProtected from '../../common/utils/axiosUtils';

// Get Patient Appointment Details by consultation Id for doctors portal

export const getAppointmentsDetails = async (id: string) => {
    return await $axiosProtected().get(
        `scheduler/schedule-consultation/consultation/web-portal?id=${id}`
    );
};

// Updates the status of Booking/Consultation in the Appointment details
export const updateConsultationStatus = async (payload: any) => {
    return await $axiosProtected().patch(
        `/scheduler/schedule-consultation/status/update/web-portal?id=674ecc21bf029cdad4e30b92`,
        payload
    );
};
