import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import ConfirmCancelModal from './ConfirmCancelModal';
import BookingGuidance from '../../features/tutorial-overlay/BookingGuidance';
import { Link } from 'react-router-dom';
import { updateConsultationStatus } from '../../../../services/patient/PatientAppointmentsDetails.hook';
import useMutateRequest from '../../../../hooks/getMutation.query';

const BookingDetails = ({
    status,
    setStatus,
    openPrescription,
    appointmentId,
    appointmentDetails,
}: any) => {
    const [isModelOpen, setIsIsModelOpen] = useState(false);
    const [isGuidanceActive, setIsGuidanceActive] = useState(false);
    const {
        taskDay,
        taskMonth,
        taskYear,
        consultationStatus,
        consultationNumber,
        consultationType,
        consultationFee,
    } = appointmentDetails || {};

    const getStatusClass = (currentStatus: string) => {
        switch (currentStatus.toLowerCase()) {
            case 'scheduled':
                return 'badge-warning';
            case 'in progress':
                return 'badge-success';
            case 'cancelled':
                return 'badge-danger';
            default:
                return 'badge-secondary';
        }
    };

    const { isLoading, mutate, isError, error } = useMutateRequest(
        (data: any) => updateConsultationStatus(data)
    );

    const handleUpdateConsultationStatus = async (newStatus: string) => {
        const payload = { consultationStatus: newStatus };
        try {
            mutate(payload, {
                onSuccess(data: any) {
                    console.log(data);
                },

                onError(error: any) {
                    console.log(error);
                },
            });
        } catch (error) {
            console.error('Error updating consultation status:', error);
        }
    };
    const handleAccept = () => {
        handleUpdateConsultationStatus('In Progress');
        setIsGuidanceActive(false);
    };

    const handleReject = () => {
        handleUpdateConsultationStatus('Cancelled');
        setIsIsModelOpen(false);
        setIsGuidanceActive(false);
    };

    const handleCloseModal = () => {
        setIsIsModelOpen(false);
    };

    // useEffect(() => {
    //     setIsGuidanceActive(true);
    //     return () => setIsGuidanceActive(false);
    // }, []);

    return (
        <div className="card shadow-sm position-relative">
            {/* Guidance Overlay */}
            {isGuidanceActive && consultationStatus === 'Scheduled' && (
                <BookingGuidance
                    onComplete={() => setIsGuidanceActive(false)}
                />
            )}
            {/* Header and Icon */}
            <div
                className="card-header bg-gradient-primary text-white d-flex align-items-center justify-content-between"
                id="booking-details"
            >
                <h2 className="mb-0 d-flex align-items-center">
                    <i className="fas fa-calendar-check me-3"></i>
                    BOOKING DETAILS
                </h2>
                <span
                    className={`badge ${getStatusClass(
                        consultationStatus || ''
                    )} rounded-pill px-3 py-2`}
                >
                    {consultationStatus}
                </span>
            </div>

            <div className="card-body">
                {/* Detailed Booking Info*/}
                <div className="row g-4">
                    <div className="col-md-6">
                        <div className="d-flex align-items-center">
                            <div>
                                <small className="text-muted">Booking ID</small>
                                <p className="mb-0 fw-bold">
                                    {consultationNumber}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center">
                            <div>
                                <small className="text-muted">Type</small>
                                <p className="mb-0 fw-bold">
                                    {consultationType}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center">
                            <div>
                                <small className="text-muted">Date</small>
                                <p className="mb-0 fw-bold">
                                    {`${taskDay} ${taskMonth} ${taskYear}`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center">
                            <div>
                                <small className="text-muted">Fees</small>
                                <p className="mb-0 fw-bold text-danger">
                                    Rs {consultationFee?.fee}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons*/}
                <hr className="my-4 border-dashed border-1" />

                {status === 'Scheduled' && (
                    <div className="d-flex justify-content-center align-items-center">
                        <div
                            className="d-inline-block text-center p-6 rounded-3 shadow-sm"
                            id="booking-action-buttons"
                        >
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="fas fa-question-circle me-3 text-primary fs-4"></i>
                                <p className="mb-0 text-primary fw-bold fs-5 animate-pulse">
                                    Do you want to proceed with this booking?
                                </p>
                            </div>
                            <div
                                className="btn-group shadow-sm mt-3"
                                role="group"
                            >
                                <button
                                    className="btn btn-outline-danger text-hover-white"
                                    onClick={() => setIsIsModelOpen(true)}
                                >
                                    <i className="fas fa-times me-1"></i>
                                    Reject
                                </button>
                                <button
                                    className="btn btn-outline-success text-hover-white"
                                    onClick={handleAccept}
                                >
                                    <i className="fas fa-check me-1"></i>
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {status === 'Accepted' && appointmentId && (
                    <div className="d-flex justify-content-end gap-3">
                        <button
                            className="btn btn-primary btn-md"
                            onClick={openPrescription}
                        >
                            Add prescription
                        </button>
                        <Link
                            target="_blank"
                            to={`/patient/patient-appointment/videocall?appointmentId=${appointmentId}`}
                        >
                            <button className="btn btn-success btn-md d-flex align-items-center">
                                <i className="fas fa-video me-3"></i>
                                Start Video Call
                            </button>
                        </Link>
                    </div>
                )}

                {/*Modal for rejecting booking*/}
                <Modal open={isModelOpen} onClose={handleCloseModal}>
                    <ConfirmCancelModal
                        handleConfirm={handleReject}
                        handleClose={handleCloseModal}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default BookingDetails;
