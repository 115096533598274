import { ErrorMessage, Field } from 'formik';

const MedicineRow = ({ index, remove, push, errors, touched }: any) => (
    <div className="row g-2 mb-2">
        <div className="col-md-4">
            <Field
                name={`medicines.${index}.name`}
                type="text"
                className={`form-control ${
                    errors.medicines && touched.medicines ? 'is-invalid' : ''
                }`}
                placeholder="Medicine Name"
            />
            <ErrorMessage
                name={`medicines.${index}.name`}
                component="div"
                className="text-danger small"
            />
        </div>

        {index > 0 && (
            <div className="col-md-1">
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => remove(index)}
                >
                    ×
                </button>
            </div>
        )}
    </div>
);
export default MedicineRow;
