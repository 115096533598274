import * as Yup from 'yup';

export const prescriptionValidationSchema = (isGlassPrescription: boolean) =>
    Yup.object().shape({
        patientComplaint: Yup.string().required(
            'Patient complaint is required.'
        ),
        doctorAdvice: Yup.string().required('Doctor advice is required.'),
        medicines: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Medicine name is required.'),
            })
        ),
        eyeParameters: Yup.object().shape({
            ipd: isGlassPrescription
                ? Yup.number()
                      .typeError('IPD must be a number.')
                      .required('IPD is required.')
                : Yup.number().typeError('IPD must be a number.'),

            dv: Yup.object().shape({
                mm: isGlassPrescription
                    ? Yup.number()
                          .typeError('DV mm must be a number.')
                          .required('DV is required.')
                    : Yup.number().typeError('DV mm must be a number.'),
            }),
            nv: Yup.object().shape({
                mm: isGlassPrescription
                    ? Yup.number()
                          .typeError('NV mm must be a number.')
                          .required('NV is required.')
                    : Yup.number().typeError('NV mm must be a number.'),
            }),
            lensAdvised: Yup.string(),
            referral: Yup.string(),
            left: Yup.object().shape({
                distance: Yup.object().shape({
                    spherical: Yup.number().typeError('Must be a number.'),
                    cylindrical: Yup.number().typeError('Must be a number.'),
                    axis: Yup.number().typeError('Must be a number.'),
                    value: Yup.number().typeError('Must be a number.'),
                }),
                near: Yup.object().shape({
                    spherical: Yup.number().typeError('Must be a number.'),
                    cylindrical: Yup.number().typeError('Must be a number.'),
                    axis: Yup.number().typeError('Must be a number.'),
                    value: Yup.number().typeError('Must be a number.'),
                }),
            }),
            right: Yup.object().shape({
                distance: Yup.object().shape({
                    spherical: Yup.number().typeError('Must be a number.'),
                    cylindrical: Yup.number().typeError('Must be a number.'),
                    axis: Yup.number().typeError('Must be a number.'),
                    value: Yup.number().typeError('Must be a number.'),
                }),
                near: Yup.object().shape({
                    spherical: Yup.number().typeError('Must be a number.'),
                    cylindrical: Yup.number().typeError('Must be a number.'),
                    axis: Yup.number().typeError('Must be a number.'),
                    value: Yup.number().typeError('Must be a number.'),
                }),
            }),
        }),
    });

export const initialValues = {
    patientComplaint: '',
    doctorAdvice: '',
    labAdvice: '',
    otherSuggestions: '',
    medicines: [{ name: '' }],
    eyeParameters: {
        ipd: '',
        dv: { mm: '' },
        nv: { mm: '' },
        lensAdvised: 'None',
        referral: '',
        left: {
            distance: { spherical: '', cylindrical: '', axis: '', value: '' },
            near: { spherical: '', cylindrical: '', axis: '', value: '' },
        },
        right: {
            distance: { spherical: '', cylindrical: '', axis: '', value: '' },
            near: { spherical: '', cylindrical: '', axis: '', value: '' },
        },
    },
};
