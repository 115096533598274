import React, { useEffect, useMemo, useState } from 'react';
import {
    LocalUser,
    RemoteUser,
    useIsConnected,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useRemoteUsers,
} from 'agora-rtc-react';

import './styles.css';
import useMutateRequest from '../../../../hooks/getMutation.query';
import { generateAgoraToken } from '../../../../services/patient/videocall.hook';
// import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const VideoCall = () => {
    const [calling, setCalling] = useState(false);
    const isConnected = useIsConnected(); // Store the user's connection status
    const [appId] = useState(process.env.REACT_APP_APP_ID as string);
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState('');
    const { mutate } = useMutateRequest(
        (payload: { channel: string; uid: string; expiry: number }) =>
            generateAgoraToken(payload)
    );
    const appointmentId = searchParams.get('appointmentId');
    const channel = useMemo(
        () =>
            process.env.REACT_APP_ENV === 'dev'
                ? `dev-ikure-consultation-${appointmentId}`
                : `ikure-consultation-${appointmentId}`,
        [appointmentId]
    );

    useEffect(() => {
        mutate(
            {
                channel: `consultation-${appointmentId}`,
                // uid: 1,
                expiry: 86400,
            },

            {
                onSuccess: (data: any) => {
                    setToken(data?.data?.data?.rtcToken);
                    setCalling(true);
                },
            }
        );
    }, [channel, mutate, appointmentId]);

    useJoin(
        { appid: appId, channel: channel, token: token ? token : null },
        calling
    );

    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn) || {};
    const { localCameraTrack } = useLocalCameraTrack(cameraOn) || {};
    usePublish([localMicrophoneTrack, localCameraTrack]);

    const remoteUsers = useRemoteUsers();
    // const navigate = useNavigate();
    return (
        <>
            <div className="relative">
                {
                    isConnected && (
                        <div className="w-100 relative">
                            {remoteUsers?.length === 0 ? (
                                <div className="w-full">
                                    <LocalUser
                                        audioTrack={localMicrophoneTrack}
                                        cameraOn={cameraOn}
                                        micOn={micOn}
                                        videoTrack={localCameraTrack}
                                        style={{
                                            width: '100vw',
                                            height: '85vh',
                                            objectFit: 'contain',
                                        }}
                                    >
                                        <samp className="user-name">You</samp>
                                    </LocalUser>
                                </div>
                            ) : (
                                <div
                                    className="w-full"
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        bottom: 10,
                                        width: '200px',
                                        height: '200px',
                                    }}
                                >
                                    <LocalUser
                                        audioTrack={localMicrophoneTrack}
                                        cameraOn={cameraOn}
                                        micOn={micOn}
                                        videoTrack={localCameraTrack}
                                        style={{
                                            width: '200px',
                                            height: '200px',
                                            zIndex: 200,
                                            objectFit: 'contain',
                                        }}
                                    >
                                        <samp className="user-name">You</samp>
                                    </LocalUser>
                                </div>
                            )}
                            {remoteUsers?.map((user) => (
                                <div
                                    className="w-full"
                                    data-testid="remote-user"
                                    key={user.uid}
                                >
                                    <RemoteUser
                                        style={{
                                            width: '100vw',
                                            height: '85vh',
                                            objectFit: 'contain',
                                        }}
                                        user={user}
                                    >
                                        <samp className="user-name">
                                            {user.uid}
                                        </samp>
                                    </RemoteUser>
                                </div>
                            ))}
                        </div>
                    )
                    // : (
                    //     <div className="join-room">
                    //         <input
                    //             onChange={(e) => setAppId(e.target.value)}
                    //             placeholder="<Your app ID>"
                    //             value={appId}
                    //         />
                    //         <input
                    //             onChange={(e) => setChannel(e.target.value)}
                    //             placeholder="<Your channel Name>"
                    //             value={channel}
                    //         />
                    //         <input
                    //             onChange={(e) => setToken(e.target.value)}
                    //             placeholder="<Your token>"
                    //             value={token}
                    //         />

                    //         <button
                    //             className={`join-channel ${
                    //                 !appId || !channel ? 'disabled' : ''
                    //             }`}
                    //             data-testid="join-channel"
                    //             disabled={!appId || !channel}
                    //             onClick={() => setCalling(true)}
                    //         >
                    //             <span>Join Channel</span>
                    //         </button>
                    //     </div>
                    // )
                }
            </div>
            {isConnected && (
                <div
                    className="d-flex "
                    style={{
                        zIndex: 200,
                        bottom: 5,
                        left: '55%',
                        position: 'absolute',
                    }}
                >
                    <div>
                        <button
                            className="btns"
                            data-testid="mic-button"
                            aria-label={micOn ? 'Mute mic' : 'Unmute mic'}
                            onClick={() => setMic((a) => !a)}
                        >
                            <i
                                className={`bi ${
                                    micOn ? 'bi-mic-fill' : 'bi-mic-mute-fill'
                                }`}
                                style={{ color: 'white' }}
                            />
                        </button>
                        <button
                            className="btns"
                            data-testid="camera-button"
                            aria-label={
                                cameraOn ? 'Turn off camera' : 'Turn on camera'
                            }
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                            onClick={() => setCamera((a) => !a)}
                        >
                            <i
                                className={`bi ${
                                    cameraOn
                                        ? 'bi-camera-video-fill'
                                        : 'bi-camera-video-off-fill'
                                }`}
                                style={{ color: 'white' }}
                            />
                        </button>
                    </div>
                    <button
                        className={`btns btns-phone ${
                            calling ? 'btns-phone-active' : ''
                        }`}
                        onClick={() => {
                            setCalling((a) => !a);
                            window.close();
                        }}
                    >
                        {
                            // calling ? (
                            //     <i
                            //         className="bi bi-telephone-x-fill"
                            //         style={{ color: 'white' }}
                            //     />
                            // ) :
                            <i
                                className="bi bi-telephone-fill"
                                style={{ color: 'white' }}
                            />
                        }
                    </button>
                </div>
            )}
        </>
    );
};

export default VideoCall;
