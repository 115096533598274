import React, { useEffect } from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
// import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { useLocation } from 'react-router-dom';

import { MenuComponent } from '../assets/ts/components';
import Navbar from '../../components/Navbar';

type Props = {
    children?: React.ReactNode;
};

const MasterLayout = ({ children }: Props) => {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 500);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 500);
    }, [location.key]);
    const path = window.location.pathname.split('/');

    return (
        <PageDataProvider>
            <div className="page d-flex flex-row flex-column-fluid">
                <AsideDefault />
                <div
                    style={{
                        paddingTop: '0px',
                        width: '100%',
                        height: '100vh',
                    }}
                    className="wrapper d-flex flex-column flex-row-fluid"
                    id="kt_wrapper"
                >
                    <HeaderWrapper />

                    <div
                        id="kt_content"
                        className="content d-flex m-5 flex-column flex-column-fluid"
                    >
                        {/* <Toolbar /> */}
                        {path[path.length - 2] !== 'videocall' && (
                            <div className="card mb-5 mt-12 p-1">
                                <Navbar />
                            </div>
                        )}
                        <div
                            className={`d-flex  flex-column-fluid w-auto overflow-y-auto`}
                            style={{
                                marginTop:
                                    path[path.length - 2] === 'videocall'
                                        ? '2rem'
                                        : '0',
                            }}
                            id="kt_post"
                        >
                            <Content>{children}</Content>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <ScrollTop />
        </PageDataProvider>
    );
};

export { MasterLayout };
