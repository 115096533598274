import * as Yup from 'yup';

export type appointmentExportValueTypes = {
    exportFormat: string;
    facilityCode: string;
    chwId: string;
    appointmentStatus: string;
    doctorName: string;
    fromDate: string;
    toDate: string;
};

export const appointmentListInitalValues: appointmentExportValueTypes = {
    exportFormat: 'CSV',
    facilityCode: '',
    chwId: '',
    doctorName: '',
    appointmentStatus: '',
    fromDate: '',
    toDate: '',
};

export const appointmentListExportValidationSchema = Yup.object({
    exportFormat: Yup.string().label('Export format is required'),
    facilityCode: Yup.string().label('Facility name is required'),
    chwId: Yup.string().label('CHW name is required'),
    appointmentStatus: Yup.string().label('CHW name is required'),
    doctorName: Yup.string().label('Doctor name is required'),
    fromDate: Yup.date()
        .required('From date is required')
        .when([], {
            is: (value: any) => value !== undefined,
            then: (schema) =>
                schema.test(
                    'fromDate',
                    'From date is required',
                    function (value) {
                        return this.parent.fromDate !== undefined;
                    }
                ),
        }),
    toDate: Yup.date()
        .required('To date is required')
        .when('fromDate', (fromDate, schema) => {
            return fromDate
                ? schema.required(
                      'To date is required when From date is specified'
                  )
                : schema.nullable();
        })
        .min(Yup.ref('fromDate'), 'To date must be greater than From date')
        .max(new Date(), 'Date must not be more than the current date')
        .when([], {
            is: (value: any) => value !== undefined,
            then: (schema) =>
                schema.test('toDate', 'To date is required', function (value) {
                    return this.parent.toDate !== undefined;
                }),
        }),
});
