import ClipLoader from 'react-spinners/ClipLoader';
import CustomTooltip from '../../components/CustomTooltip';
import { useNavbar } from '../../context/NavbarContext';

const AppointmentCounterPage = ({
    isLoading,
    totalPatientAppointmentCount,
    isPatientConsultationError,
    totalPatientConsultationEntites,
    filterData,
    countObj,
}: any) => {
    // const showSimpleCounter = Object?.values(filterData)?.some(
    //     (value) => value !== ''
    // );
    const { userRole } = useNavbar();
    return (
        <div className="p-10 card">
            <div className="d-flex">
                <h1 className="fs-1 text-dark fw-bolder">
                    APPOINTMENTS OVERVIEW
                </h1>
                <CustomTooltip
                    style={{ fontSize: '550px' }}
                    title="Overview of the Appointment Today"
                    placement="right"
                >
                    <i className="fs-2 mt-2 fas fa-info-circle ms-2 bg-transparent border-none"></i>
                </CustomTooltip>
            </div>
            <div className="d-flex mt-5">
                <>
                    {isLoading ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="w-300px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-primary">
                                {isPatientConsultationError === false
                                    ? countObj?.scheduled
                                    : 'No Data'}
                            </h3>
                            <h3 style={{ color: 'rgb(239, 169, 6)' }}>
                                SCHEDULED
                            </h3>
                        </div>
                    )}
                    {isLoading ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="w-300px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h4 className="text-primary">
                                {isPatientConsultationError === false
                                    ? countObj?.inProgress
                                    : 'No Data'}
                            </h4>
                            <h4 className="text-success">IN PROGRESS</h4>
                        </div>
                    )}
                    {isLoading ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="w-300px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h4 className="text-primary">
                                {isPatientConsultationError === false
                                    ? countObj?.done
                                    : 'No Data'}
                            </h4>
                            <h4 className="text-primary">Completed</h4>
                        </div>
                    )}
                    {isLoading ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="w-300px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h4 className="text-primary">
                                {isPatientConsultationError === false
                                    ? totalPatientConsultationEntites
                                    : 'No Data'}
                            </h4>
                            <div className="d-flex align-items-center">
                                <h4>TOTAL APPOINTMENTS</h4>
                                <CustomTooltip
                                    style={{ fontSize: '550px' }}
                                    title={`In progress: ${countObj?.inProgress} + Completed: ${countObj?.done} + Scheduled: ${countObj?.scheduled}
                                    + Cancelled: ${countObj?.cancelled} + Re-scheduled: ${countObj?.rescheduled}`}
                                    placement="top"
                                >
                                    <i className="fs-2 mb-2 fas fa-info-circle ms-2 bg-transparent border-none"></i>
                                </CustomTooltip>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default AppointmentCounterPage;
