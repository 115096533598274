import { useEffect, useState } from 'react';
import BookingDetails from './components/appointment-details/BookingDetails';
import PatientDetails from './components/appointment-details/PatientDetails';
import Vitals from './components/appointment-details/Vitals';
import PrescriptionForm from './components/appointment-details/PrescriptionForm';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { getAppointmentsDetails } from '../../services/patient/PatientAppointmentsDetails.hook';
import useGetRequest from '../../hooks/getRequest.query';

function AppointmentDetails() {
    const [isPrescriptionFormOpen, setIsPrescriptionFormOpen] =
        useState<boolean>(false);
    const [status, setStatus] = useState('Scheduled');
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const appointmentId = searchParams.get('appointmentId');
    const openPrescription = () => {
        setIsPrescriptionFormOpen(true);
    };
    const closePrescription = () => {
        setIsPrescriptionFormOpen(false);
    };

    const { data, isLoading, error } = useGetRequest(
        ['appointment-details', appointmentId],

        () =>
            appointmentId
                ? getAppointmentsDetails(appointmentId)
                : Promise.reject('No appointment ID'),

        {
            // Only enable the query if appointmentId exists
            enabled: !!appointmentId,
            onError: (error) => {
                console.error('Failed to fetch appointment details:', error);
                navigate('/patient/patient-appointment');
            },
        }
    );

    const appointmentDetails = data?.data?.data?.consultations[0];

    useEffect(() => {
        if (!appointmentId) {
            navigate('/patient/patient-appointment');
        }
    }, [appointmentId]);

    return (
        <div
            data-testid="appointment-container"
            className="overflow-hidden d-flex flex-column gap-5 bg-white rounded"
        >
            <>
                {!isPrescriptionFormOpen && (
                    <>
                        <BookingDetails
                            openPrescription={openPrescription}
                            status={appointmentDetails?.consultationStatus}
                            setStatus={setStatus}
                            appointmentId={appointmentId}
                            appointmentDetails={appointmentDetails}
                        />
                        <PatientDetails
                            id={appointmentDetails?.patientData[0]?.uhid}
                            details={appointmentDetails}
                        />
                        <Vitals
                            id={appointmentDetails?.patientData[0]?.uhid}
                            facilityCode={
                                appointmentDetails?.patientData[0]?.facilityCode
                            }
                            age={
                                appointmentDetails?.patientData[0]
                                    ?.demographicDetails.age
                            }
                            gender={
                                appointmentDetails?.patientData[0]
                                    ?.demographicDetails.sex
                            }
                        />
                    </>
                )}
            </>
            <>
                {isPrescriptionFormOpen && (
                    <PrescriptionForm onCancel={closePrescription} />
                )}
            </>
        </div>
    );
}

export default AppointmentDetails;
