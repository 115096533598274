import { ErrorMessage, Field } from 'formik';

const EyePrescriptionFields = () => (
    <div className="mb-4">
        <h4 className="mb-4">Eye Parameters</h4>
        <div className="row d-flex justify-content-center gap-8">
            {/* Right Eye */}
            <div className="col-md-5 bg-light p-3 rounded">
                <h5 className="text-center mb-6">
                    <i className="fa-regular fa-eye text-primary"></i> Right Eye
                </h5>
                <div className="row">
                    {/* Distance and Inputs */}
                    <div className="col-2 d-flex align-items-center">
                        <h6 className="text-start">Distance</h6>
                    </div>
                    <div className="col-10">
                        <div className="row g-3">
                            <div className="col-md-3">
                                <label className="form-label">Spherical</label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.right.distance.spherical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.distance.spherical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">
                                    Cylindrical
                                </label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.right.distance.cylindrical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.distance.cylindrical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Axis</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.right.distance.axis"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.distance.axis"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Value</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.right.distance.value"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.distance.value"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-6 pt-3 border-top border-2 border-top">
                    {/* Near and Inputs */}
                    <div className="col-2 d-flex align-items-center">
                        <h6 className="text-start">Near</h6>
                    </div>
                    <div className="col-10">
                        <div className="row g-3">
                            <div className="col-md-3">
                                <label className="form-label">Spherical</label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.right.near.spherical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.near.spherical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">
                                    Cylindrical
                                </label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.right.near.cylindrical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.near.cylindrical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Axis</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.right.near.axis"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.near.axis"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Value</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.right.near.value"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.right.near.value"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Left Eye */}
            <div className="col-md-5 bg-light p-3 rounded">
                <h5 className="text-center mb-6">
                    <i className="fa-regular fa-eye text-primary"></i> Left Eye
                </h5>
                <div className="row">
                    {/* Distance and Inputs */}
                    <div className="col-2 d-flex align-items-center">
                        <h6 className="text-start">Distance</h6>
                    </div>
                    <div className="col-10">
                        <div className="row g-3">
                            <div className="col-md-3">
                                <label className="form-label">Spherical</label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.left.distance.spherical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.distance.spherical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">
                                    Cylindrical
                                </label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.left.distance.cylindrical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.distance.cylindrical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Axis</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.left.distance.axis"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.distance.axis"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Value</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.left.distance.value"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.distance.value"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-6 pt-3 border-top border-2 border-top">
                    {/* Near and Inputs */}
                    <div className="col-2 d-flex align-items-center">
                        <h6 className="text-start">Near</h6>
                    </div>
                    <div className="col-10">
                        <div className="row g-3">
                            <div className="col-md-3">
                                <label className="form-label">Spherical</label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.left.near.spherical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.near.spherical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">
                                    Cylindrical
                                </label>
                                <Field
                                    type="number"
                                    step="0.25"
                                    name="eyeParameters.left.near.cylindrical"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.near.cylindrical"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Axis</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.left.near.axis"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.near.axis"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Value</label>
                                <Field
                                    type="number"
                                    name="eyeParameters.left.near.value"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="eyeParameters.left.near.value"
                                    component="div"
                                    className="text-danger small mt-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* IPD, DV, NV, Lens Advised, Referral Section */}
            <div className="row mb-4">
                <div className="col-md-3">
                    <label className="form-label required">IPD</label>
                    <Field
                        type="number"
                        name="eyeParameters.ipd"
                        className="form-control"
                        placeholder="Inter-Pupillary Distance"
                    />
                    <ErrorMessage
                        name="eyeParameters.ipd"
                        component="div"
                        className="text-danger small mt-1"
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label required">DV (mm)</label>
                    <Field
                        type="number"
                        name="eyeParameters.dv.mm"
                        className="form-control"
                        placeholder="Distance Vision"
                    />
                    <ErrorMessage
                        name="eyeParameters.dv.mm"
                        component="div"
                        className="text-danger small mt-1"
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label required">NV (mm)</label>
                    <Field
                        type="number"
                        name="eyeParameters.nv.mm"
                        className="form-control"
                        placeholder="Near Vision"
                    />
                    <ErrorMessage
                        name="eyeParameters.nv.mm"
                        component="div"
                        className="text-danger small mt-1"
                    />
                </div>
                <div className="col-md-3">
                    <label className="form-label">Lens Advised</label>
                    <Field
                        as="select"
                        name="eyeParameters.lensAdvised"
                        className="form-control"
                    >
                        <option value="None">None</option>
                        <option value="SV">Single Vision (SV)</option>
                        <option value="ARC">
                            Abnormal Retinal Correspondence (ARC)
                        </option>
                        <option value="BF">Bifocal </option>
                        <option value="PG">Progressive</option>
                        <option value="PC">Photo Chromatic</option>
                        <option value="White">White</option>
                        <option value="CR">Corneal Reflection (CR)</option>
                    </Field>
                </div>
                {/* Referral Section */}
                <div className="col-md-4 mb-4">
                    <label className="form-label">Referral</label>
                    <Field
                        type="text"
                        name="eyeParameters.referral"
                        className="form-control"
                        placeholder="Referral Details"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default EyePrescriptionFields;
